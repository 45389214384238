//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Tags } from '@/services/api.service';

export default {
  name: 'TagFilter',
  props: ['filter', 'label', 'icon', 'src', 'prefill'],
  components: {},
  data() {
    return {
      tagsSelected: null,
      tags: null,
      tagList: null,
    };
  },
  methods: {
    filterFn(val, update) {
      if (this.tagList !== null) {
        // already loaded
        return update();
      }
      let filter = this.filter;
      update(() => {
        if (filter) {
          this.tagList = this.tagList.filter((i) => i.category === filter);
        } //else
      });
    },

    abortFilterFn() {},

    prefillTags() {
      Tags.list(this.filter).then((res) => {
        if (
          res.data &&
          res.data.data &&
          res.data.data.getTags &&
          res.data.data.getTags.length
        ) {
          this.tagList = res.data.data.getTags;
        } else {
          this.tagList = [];
        }
      });
    },
  },
  mounted() {
    this.tagsSelected = this.prefill;
    this.prefillTags();
  },
  created() {},
  watch: {
    tagsSelected(newval) {
      this.$emit('tagChange', newval);
    },
    prefill(newval) {
      this.tagsSelected = newval;
    },
  },
};
